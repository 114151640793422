import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';

import { StorageService } from '@services/logic/storage.service';

import { environment } from '../../../environments/handyhand/environment.dk';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  decodedToken: any;

  private authStatusSource = new BehaviorSubject<boolean>(
    this.isAuthenticated(),
  );
  public authStatus$ = this.authStatusSource.asObservable();
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private router: Router,
  ) {}

  login(data: { email: string; password: string }): Observable<any> {
    const url = `${environment.apiUrl}/users/login`;

    return this.http.post<any>(url, data).pipe(
      tap(() => {
        this.authStatusSource.next(this.isAuthenticated());
      }),
    );
  }

  getToken(): string {
    return this.storage.authToken;
  }

  getDecodedToken(): any {
    if (!this.decodedToken) {
      const token = this.getToken();
      if (!token) {
        return null;
      }
      const helper = new JwtHelperService();
      this.decodedToken = helper.decodeToken(token);
    }

    return this.decodedToken;
  }

  getUserIdFromAuthToken(): number {
    const decodedToken = this.getDecodedToken();
    if (!decodedToken) {
      return 0;
    }
    return decodedToken.userId;
  }

  logout(): void {
    this.decodedToken = null;
    // Keep lang in storage after you logout
    const { lang } = this.storage;
    this.storage.clear();
    this.storage.lang = lang;
    this.router.navigate(['/']);
    this.authStatusSource.next(this.isAuthenticated());
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }
    const helper = new JwtHelperService();
    const isExpired = helper.isTokenExpired(token);
    return !isExpired;
  }
}
